module.exports = {
  type: [
    { label: "Venda", value: "Venda" },
    { label: "Locação", value: "Locação" },
  ],
  city: [
    { label: "Cascavel", value: "Cascavel" },
    { label: "Toledo", value: "Toledo" },
  ],
  district: [
    { label: "Centro", value: "Centro" },
    { label: "Cancelli", value: "Cancelli" },
    { label: "Country", value: "Country" },
    { label: "São Cristóvão", value: "São Cristóvão" },
    { label: "Pacaembu", value: "Pacaembu" },
    { label: "Região do Lago", value: "Região do Lago" },
    { label: "Pq São Paulo", value: "Pq São Paulo" },
    { label: "Neva", value: "Neva" },
    { label: "Pioneiros Catarinenses", value: "Pioneiros Catarinenses" },
    { label: "Santo Onofre", value: "Santo Onofre" },
    { label: "Alto Alegre", value: "Alto Alegre" },
    { label: "Coqueiral", value: "Coqueiral" },
    { label: "Parque Verde", value: "Parque Verde" },
    { label: "Recanto Tropical", value: "Recanto Tropical" },
    { label: "Canadá", value: "Canadá" },
    { label: "Brasmadeira", value: "Brasmadeira" },
    { label: "Interlagos", value: "Interlagos" },
    { label: "Floresta", value: "Floresta" },
    { label: "Brasília", value: "Brasília" },
    { label: "Periolo", value: "Periolo" },
    { label: "Morumbi", value: "Morumbi" },
    { label: "Cataratas", value: "Cataratas" },
    { label: "Cascavel Velho", value: "Cascavel Velho" },
    { label: "Santa Felicidade", value: "Santa Felicidade" },
    { label: "Universitário", value: "Universitário" },
    { label: "XIV De Novembro", value: "XIV De Novembro" },
    { label: "Santos Dumont", value: "Santos Dumont" },
    { label: "Santa Cruz", value: "Santa Cruz" },
    { label: "Esmerald", value: "Esmerald" },
    { label: "Maria Luiza", value: "Maria Luiza" },
    { label: "Vista Linda", value: "Vista Linda" },
    { label: "Santo Inácio/FAG", value: "Santo Inácio/FAG" },
  ],
  definition: [
    { label: "Apartamento", value: "Apartamento" },
    { label: "Casa", value: "Casa" },
    { label: "Sobrado", value: "Sobrado" },
    { label: "Terreno", value: "Terreno" },
    { label: "Sala Comercial", value: "Sala Comercial" },
  ],
};
