import React from "react";

import { NavBar } from "../../components";

import { Container } from "./styles";

const Config = () => {
  return <Container />;
};

export default Config;
